import React from 'react';
import styled from 'styled-components';
import {Popconfirm, Button, message} from 'antd';
import GenericForm from 'rev.sdk.js/Generic/Form';
import withPageEntry from '../../withPageEntry';
import AdminLineDivider from '../../Components/AdminLineDivider';
import * as AppActions from '../../AppActions';
import * as AppActionsEx from '../../AppActions/custom';
import AdminSelectUserCustomWidget from '../../Generators/AdminResource/AdminSelectUserCustomWidget';

async function onPreviewEmail(queries) {
  try {
    AppActions.setLoading(true);

    queries['is_preview'] = true;

    window.open(
      `/print-preview?target=annualFeeNotify&query=${JSON.stringify(queries)}`,
    );
  } catch (err) {
    console.warn(err);
    message.destroy();
    message.error('發生錯誤');
  } finally {
    AppActions.setLoading(false);
  }
}

async function onSendEmail(queries) {
  try {
    message.info({
      content: `正在 email 給會員，請勿關閉或重新整理頁面`,
      duration: 0,
    });

    AppActions.setLoading(true);

    queries['is_preview'] = false;

    await AppActionsEx.adminPostAnnaulFeeNotify(queries);

    message.destroy();
    message.success('通知完成');
  } catch (err) {
    console.warn(err);
    message.destroy();
    message.error('發生錯誤');
  } finally {
    AppActions.setLoading(false);
  }
}

const required = ['year_start', 'year_end', 'sender'];

function AdminAnnualFeeNotify(props) {
  const [isPreviewed, setIsPreviewed] = React.useState(false);
  const rjsfProps = {
    widgets: {
      'admin-select-user-custom-widget': AdminSelectUserCustomWidget,
    },
  };

  return (
    <Wrapper>
      <div className="title-container">
        <h1 className="title">常年會費：訊息通知</h1>
      </div>
      <div className="container">
        <AdminLineDivider title="基本設定" />
        <GenericForm
          rjsfProps={rjsfProps}
          schema={{
            title: '',
            type: 'object',
            required,
            properties: {
              year_start: {
                type: 'string',
                title: '開徵年度（起）',
              },
              year_end: {
                type: 'string',
                title: '開徵年度（迄）',
              },
              sender: {
                type: 'string',
                title: '通知類型',
                default: 'on-member-annual',
                enum: [
                  'on-member-annual',
                  'on-member-december',
                  'on-member-march',
                  // 'on-member-april',
                  // 'on-member-may',
                ],
                enumNames: [
                  '開徵',
                  '催繳',
                  '已取得執行名義之催繳',
                  // '四月',
                  // '五月',
                ],
              },
              state: {
                type: 'integer',
                title: '狀態',
                enum: [1, 2],
                enumNames: ['入會', '退會'],
              },
              owner: {
                type: 'string',
                title: '會員',
              },
              start_date: {
                type: 'string',
                title: '開徵日期（起）',
                format: 'date',
              },
              end_date: {
                type: 'string',
                title: '開徵日期（迄）',
                format: 'date',
              },
            },
          }}
          uiSchema={{
            type: {
              'ui:widget': 'radio',
              'ui:options': {
                inline: true,
              },
            },
            owner: {
              'ui:widget': 'admin-select-user-custom-widget',
            },
          }}
          renderCustomSubmitButton={(params) => {
            const {values} = params;
            const {
              year_start,
              year_end,
              sender,
              state,
              owner,
              start_date,
              end_date,
            } = values;

            const queries = {};

            const startNum = Number(year_start);
            const endNum = Number(year_end);

            if (!!year_start && isNaN(startNum)) {
              message.warning('請輸入正確開徵年度(起)');
              return;
            }

            if (!!year_end && isNaN(endNum)) {
              message.warning('請輸入正確開徵年度(迄)');
              return;
            }

            if (year_start === year_end) {
              queries.annual_years = [year_start];
            }

            if (year_start !== year_end) {
              const years = [year_start];
              const diff = endNum - startNum;

              for (let i = 0; i < diff; i++) {
                years.push(`${startNum + i + 1}`);
              }

              queries.annual_years = years;
            }

            if (!!sender) {
              queries.sender = sender;
            }

            if (!!owner) {
              queries.owner = owner;
            }

            if (!!state) {
              queries.state = state;
            }

            if (!!start_date) {
              queries.start_time = new Date(start_date).getTime();
            }

            if (!!end_date) {
              queries.end_time = new Date(end_date).getTime();
            }

            return (
              <div className="actions">
                <Button
                  disabled={!year_start || !year_end || !sender}
                  onClick={() => {
                    setIsPreviewed(false);
                    onPreviewEmail(queries);
                    setIsPreviewed(true);
                  }}>
                  產生預覽
                </Button>

                {isPreviewed && (
                  <Popconfirm
                    title={`確認送出通知嗎？`}
                    okText="確認"
                    cancelText="取消"
                    disabled={!year_start || !year_end || !sender}
                    onCancel={() => 0}
                    onConfirm={() => onSendEmail(queries)}>
                    <Button
                      type="primary"
                      style={{marginLeft: 15}}
                      disabled={!year_start || !year_end || !sender}>
                      送出通知
                    </Button>
                  </Popconfirm>
                )}
              </div>
            );
          }}
        />
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  max-width: 800px;
  width: 100%;
  padding: 20px;

  & > .title-container {
    & > .title {
      margin-bottom: 10px;
      font-size: 32px;
    }
  }

  & > .container {
  }

  & .actions {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
  }
`;

export default withPageEntry(AdminAnnualFeeNotify);
